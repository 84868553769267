<template>
  <div class="content">
    <el-row class="bgW" v-if="userInfo.type != 3">
      <el-col :span="22">
        <!--检索条件-->
        <el-form class="search" :inline="true" label-suffix="：" size="mini" :model="search">
          <el-form-item label="店铺" v-if="!userInfo.shop_id">
            <el-select
              v-model="search.shopId"
              clearable
              remote
              filterable
              :remote-method="remoteMethod"
              placeholder="请输入店铺名称"
              class="w140"
            >
              <el-option
                v-for="item in options.shopId"
                :key="item.shopId"
                :label="item.shopName"
                :value="item.shopId"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="员工名称">
            <el-input v-model="search.name" maxlength="30" class="w140" placeholder="请输入员工名称" />
          </el-form-item>
          <el-form-item label="员工联系方式">
            <el-input v-model="search.phone" maxlength="30" class="w140" placeholder="请输入员工联系方式" />
          </el-form-item>
          <el-form-item label="员工职位">
            <el-select v-model="search.positionId" clearable class="w140" placeholder="请选择员工职位">
              <el-option
                v-for="item in options.positionId"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="状态">
            <el-select v-model="search.status" clearable class="w140" placeholder="请选择统状态">
              <el-option
                v-for="item in options.status"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="2" style="text-align: right">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
      </el-col>
    </el-row>
    <div class="accountCon">
      <el-button
        v-if="!userInfo.shop_id"
        type="primary"
        :disabled="!idList.length"
        class="mb10"
        size="mini"
        @click="hint('导出选中列表', 'export')"
      >导出数据</el-button>
      <el-button type="primary" class="mb10" size="mini" @click="scoreExport = true">员工评分导出</el-button>
      <!--列表-->
      <el-row>
        <el-col :span="24">
          <el-table
            :data="list"
            size="small"
            border
            v-loading="loading"
            :cell-style="$style.cellStyle"
            :header-cell-style="$style.rowClass"
            @selection-change="handleSelectionChange"
          >
            <ElTableColumn
              v-if="userInfo.type != 3 && !userInfo.shop_id"
              type="selection"
              width="50"
            />
            <ElTableColumn label="员工名称" prop="name" />
            <ElTableColumn label="员工电话" prop="phone" width="100" />
            <ElTableColumn label="性别" prop="sex" width="60" />
            <ElTableColumn label="年龄(岁)" prop="age" width="70" />
            <ElTableColumn label="是否通过试用期">
              <template slot-scope="{ row }">
                <div v-if="row.isProbation == 1">否</div>
                <div v-else>是</div>
              </template>
            </ElTableColumn>
            <ElTableColumn label="入职时间" prop="entryAt" width="140" />
            <ElTableColumn label="归属市场" prop="marketName" />
            <ElTableColumn label="归属门店" prop="shopName" />
            <ElTableColumn label="职务" prop="positionName" />
            <ElTableColumn label="岗位" prop="stationName" />
            <ElTableColumn label="状态" width="80">
              <template slot-scope="{ row }">
                <div v-if="row.status == 1">在职</div>
                <div v-if="row.status == 2">禁用</div>
                <div v-if="row.status == 3">离职</div>
              </template>
            </ElTableColumn>
            <ElTableColumn label="工龄(年)" prop="jobAge" width="70" />
            <ElTableColumn label="操作" fixed="right" width="120">
              <template slot-scope="{ row }">
                <span class="theme" @click="tapBtn('detail', row.id)">查 看</span>
                <span
                  class="theme ml20"
                  v-if="userInfo.type != 3"
                  @click="tapBtn('update', row.id)"
                >编 辑</span>
              </template>
            </ElTableColumn>
          </el-table>
          <pagination
            v-if="userInfo.type != 3"
            class="page tc mt10"
            :total="page.total"
            :page.sync="page.page"
            :limit.sync="page.limit"
            @pagination="getList"
          />
        </el-col>
      </el-row>
    </div>
    <!-- 新增弹框 -->
    <el-dialog
      title="员工评分导出"
      :visible.sync="scoreExport"
      :close-on-click-modal="false"
      width="30%"
      @close="closeTask"
    >
      <el-form
        ref="taskDetail"
        label-position="right"
        :model="taskDetail"
        class="frame"
        size="mini"
      >
        <el-form-item label="年/月" prop="moneth">
          <el-date-picker
            v-model="time"
            type="month"
            class="w300"
            value-format="yyyy-MM"
            @change="changeTime"
            placeholder="选择月份"
          ></el-date-picker>
        </el-form-item>

        <div class="footerBtn mt50">
          <el-button
            type="primary"
            size="mini"
            class="mainBtn"
            @click="submitForm('taskDetail')"
          >确认导出</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import pagination from "@/components/Pagination";
import { getShopList } from "@/api/shop";
import {
  staffList,
  readAdminPositionList,
  staffExport,
  getperson
} from "@/api/staff";
class Page {
  total = 0;
  page = 1;
  limit = 10;
}
class Search {
  shopId = ""; // 店铺
  name = ""; // 员工名称
  phone = ""; // 员工电话
  positionId = ""; // 职位
  status = ""; // 状态
}
class TaskDetail {
  year = ""; // 年份
  moneth = ""; // 月份
}
class Options {
  constructor() {
    this.positionId = [];
    this.shopId = [];
    this.status = [
      { id: 1, name: "激活" },
      { id: 2, name: "禁用" },
      { id: 3, name: "离职" }
    ];
  }
}
export default {
  name: "OrderList",
  components: {
    pagination
  },
  data() {
    return {
      search: new Search(), // 检索条件
      taskDetail: new TaskDetail(),
      options: new Options(), // 选项
      page: new Page(), // 分页
      list: [], // 数据展示
      idList: "",
      loading: false,
      scoreExport: false,
      time: ""
    };
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  mounted() {
    this.getOptions();
    this.getList();
  },
  methods: {
    // 获取选项
    getOptions() {
      readAdminPositionList().then(res => {
        this.options.positionId = res.list;
      });
    },
    remoteMethod(e) {
      getShopList({ page: 1, limit: 100000, shopName: e }).then(res => {
        this.options.shopId = res.list;
      });
    },
    // 获取列表
    getList(option) {
      this.loading = true;
      if (option === "search") this.page = new Page();
      staffList({ ...this.search, ...this.page }).then(res => {
        this.list = res.list;
        this.page.total = res.dataCount;
        this.loading = false;
      });
    },
    // 选择时间
    changeTime(e) {
      var index = e.lastIndexOf("-");
      this.taskDetail.year = e.substring(0, index);
      this.taskDetail.moneth = e.substring(index + 1, e.length);
    },
    // 关闭弹窗
    closeTask() {
      this.taskDetail = new TaskDetail();
      this.time = "";
    },
    // 提交
    submitForm(formName) {
      // this.checking();
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.hint("导出员工评分", "scoreExport");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 点击详情
    tapBtn(type, id) {
      switch (type) {
        case "detail":
          this.$router.push("./staffDetail?id=" + id);
          break;
        case "update":
          this.$router.push("./addStaff?id=" + id);
          break;
      }
    },
    // 提示
    hint(text, type) {
      this.$confirm(`${text},是否确定？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          if (type == "scoreExport") {
            this.getScoreExport();
          } else {
            this.getExport();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    // 导出
    getExport() {
      staffExport({ id: this.idList }).then(res => {
        window.open(res.data.url);
      });
    },
    // 员工评分导出
    getScoreExport() {
      getperson({ ...this.taskDetail }).then(res => {
        window.open(res.data.url);
        this.scoreExport = false;
      });
    },
    // 改变表格选中状态
    handleSelectionChange(val) {
      this.idList = val.map(i => {
        return i.id;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.bgW {
  background: #fff;
  border-radius: 3px;
  margin-bottom: 20px;
}
.el-form-item {
  margin: 5px 40px 3px 0;
}
.accountCon {
  background: #fff;
  padding: 10px 0px 80px;
}
.footerBtn {
  text-align: right;
}
</style>
